import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { getFormattedDayOfWeek } from '../../Utils/DateTime'

const getDateForEvent = (evt) => {
  return new Date(evt?.node?.data?.start_time)
}
const getSceneForEvent = (evt) => {
  return evt?.node?.data?.stage?.document?.data?.title?.text
}

let colorIndex = 0
const getNextColor = () => {
  const bgArray = ['bg-yellow-wolf2025', 'bg-blue-wolf2025', 'bg-pink-wolf2025']
  return bgArray[(colorIndex++) % bgArray.length]
}

const FilteredActivities = ({ eventList }) => {
  return (
    <div className="mt-12">
      {eventList.length === 0 ? (
        <p className="italic">Événements à venir.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
          {eventList.map((element, index) => (
            <a 
              key={index} 
              className="text-center z-0 group relative w-full max-h-64 overflow-hidden border-purple-wolf2025 border-[3px] border-x-[3px] rounded-3xl"
              href={`/evenements/${element.node.slug}`}>

              {/* IMAGE */}
              <div className="overflow-hidden">
                <GatsbyImage
                  className="w-full h-full"
                  image={getImage(element.node.data.main_image)}
                  alt={`Photo de ${element.node.data.title.text}`}
                />
              </div>

              {/* TEXT CONTAINER */}
              <div className={`absolute bottom-0 w-full border-t-[3px] border-purple-wolf2025 transition-all duration-300 flex flex-col items-center overflow-hidden 
                h-1/3 group-hover:h-1/2 ${getNextColor()}`}>

                {/* TITRE - 1/3 toujours visible */}
                <div className="flex items-center justify-center h-full transition-all duration-300 !py-0 !my-0">
                  <h2 className="!font-black !font-obviously !text-purple-wolf2025 !text-xs uppercase tracking-wider !leading-4 !px-2 text-center !py-0 !my-0">
                    {element.node.data.title.text}
                  </h2>
                </div>

                {/* DETAILS - 1/6 + 1/6 (invisible au départ) */}
                <div className="opacity-0 w-full h-0 group-hover:opacity-100 group-hover:h-1/3 transition-all duration-300 flex flex-col justify-center !py-0 !my-0">
                  <h3 className="uppercase italic !font-obviously !font-regular !text-xxs !text-purple-wolf2025 !tracking-tight !px-0 !py-0 !my-0">
                    {getFormattedDayOfWeek(getDateForEvent(element))}
                  </h3>
                  <h4 className="uppercase italic !font-obviously !font-regular !text-xxs !text-purple-wolf2025 !py-0 !px-0 !mt-0 !mb-2">
                    {getSceneForEvent(element)}
                  </h4>
                </div>

              </div>

            </a>
          ))}
        </div>
      )}
    </div>
  )
}

export default FilteredActivities

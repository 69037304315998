import React from 'react'
import { graphql } from 'gatsby'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import FilteredActivities from '../components/NewTheme/Programmation/FilteredActivities'

const PlaisirsDivers = ({data}) => {
  const metaTitle = 'Plaisirs divers | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul, plaisirs'

  const miscEvents = data.allPrismicEvents.edges.filter(({ node }) => {
    // return node.data.is_show === true && node.data.isplaisirdivers === true
    return node.data.isplaisirdivers === true
  })

  return (
    <Wolflayout mobileTitle={'Plaisirs divers'}>
        <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
        <Banner title="Plaisirs divers" />
        <div className="container content pb-12">

            {/* TOP TEXT */}
            <div className='text-center'>
                <h4 className='!font-obviously !font-regular !text-xl'>On ne vit pas que de musique et de shows, non? Si tu veux changer de beat et avoir du fun ailleurs que dans une foule, cette section est faite pour toi! Tu y trouveras les activités auxquelles tu pourras prendre part pour vivre une expérience plus décontractée. Que ce soit avec un 5 à 7 arrosé ou un karaoké endiablé, tu sauras certainement trouver chaussure à ton pied!</h4>
            </div>

            {/* PROG */}
            <div>
                <FilteredActivities eventList={miscEvents} />
            </div>

        </div>
    </Wolflayout>
    )
}

export const query = graphql`
query {
    allPrismicEvents {
        edges {
            node {
                uid
                slug
                    data {
                        main_image {
                            alt
                            copyright
                            gatsbyImageData
                            url
                        }
                        title {
                            text
                        }
                        start_time
                        end_time
                        description {
                            text
                        }
                        lpdv_id {
                            text
                        }
                        is_show
                        free
                        isbienetre
                        isentrevue
                        isconference
                        isplaisirdivers
                        isfamille
                        hidefromprog
                        stage {
                            document {
                            ... on PrismicStages {
                                uid
                                slug
                                data {
                                    title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`

export default PlaisirsDivers

// dateTime parameter should be create by passing a raw datetime in new Date(i)

export const getFormattedDayOfWeek = (dateTime) => {

    //  Day of week
    const date = dateTime?.getHours() < 4 ? dateTime?.getDate() - 1 : dateTime?.getDate()
    dateTime?.setDate(date)
    const dayOfWeek = dateTime?.toLocaleString('fr-CA', { weekday: 'long' })
    const formattedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)

    // Day in month
    const dayInMonth = dateTime?.getDate()

    // Month
    const month = dateTime?.toLocaleString('fr-CA', { month: 'long' })
    const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1)

    return formattedDayOfWeek + ' ' + dayInMonth + ' ' + formattedMonth
}